.messages-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
}

.messages-list {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  height: 400px;
  overflow-y: auto;
}

.message-item {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}

.message-input {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.message-input input {
  flex-grow: 1;
  padding: 5px;
  margin-right: 10px;
}

.message-input button {
  padding: 5px 10px;
}
