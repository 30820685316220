.overview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.item-overview {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 25%;
  margin-bottom: 10px;
}

.item-text {
  padding-top: 5px;
  font-family: "Outfit", sans-serif;
  font-size: 0.8em;
  margin-left: 5px;
  margin-right: 5px;
}

.tooltip-content-overview {
  font-size: 18px;
  width: 150px;
  color: white;
  display: block;
  text-align: center;
}

/* Styles for screens smaller than 550 */
@media (max-width: 550px) {
  .item-text {
    padding-top: 5px;
    font-family: "Outfit", sans-serif;
    font-size: 0.7em;
    margin-left: 5px;
    margin-right: 5px;
  }

  .item-overview {
    width: 50%;
    margin-bottom: 5px;
  }
}
