/* Default styles */
.container {
  display: flex;
  max-width: 1300px;
  margin: auto;
}

/* Add this to your existing CSS or create a new CSS file */
.return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.return-to-top:hover {
  background-color: #0056b3;
}

.left-column {
  width: 60%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 5%;
  margin-right: 5%;
  height: 100%;
}

.right-column {
  height: 100%;
  width: 25%;
  position: absolute; /* Initially absolute positioning */
  top: 38%;
  right: 9%;
  transition: position 0.3s ease; /* Add a smooth transition for better user experience */
}

.right-column.sticky {
  position: fixed;
  top: 8%;
}

/* Styles for screens smaller than 768px */
@media (max-width: 900px) {
  container {
    display: block; /* Reset to default value for display */
  }

  .left-column,
  .right-column {
    width: auto; /* Reset to default value for width */
    position: static; /* Reset to default value for position */
    top: auto; /* Reset to default value for top */
    right: auto; /* Reset to default value for right */
  }

  .right-column.sticky {
    position: relative;
    top: auto;
  }
}

/* Styles for screens smaller than 550 */
@media (max-width: 560px) {
  .container {
    max-width: 800px;
    display: block; /* Reset to default value for display */
  }

  .left-column,
  .right-column {
    width: auto; /* Reset to default value for width */
    position: static; /* Reset to default value for position */
    top: auto; /* Reset to default value for top */
    right: auto; /* Reset to default value for right */
  }
}

/* Styles for screens larger than 768px */
@media (min-width: 1650px) {
  .container {
    display: flex;
  }

  .left-column {
    width: 70%;
  }
  .right-column {
    height: 100%;
    width: 30%; /* Adjust the width to your preference */
    position: fixed;
    top: 30%; /* Adjust the top position as needed */
    right: 10%; /* Adjust the right position as needed */
  }
}

/* Styles for screens larger than 1000px */
@media (min-width: 1200px) {
  .right-column {
    width: 300px;
  }
}
