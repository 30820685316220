.image-list {
  display: flex;
  flex-wrap: wrap;
}

.image-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  z-index: 1; /* Ensure delete button appears above images */
}
