.container-amenity {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.item-amenity {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 25%;
}

.item-text-a {
  padding-top: 5px;
  padding-left: 4%;
  font-family: "Outfit", sans-serif;
  font-size: 0.9em;
  font-weight: bold;
}

/* Styles for screens smaller than 550 */
@media (max-width: 550px) {
  .item-amenity {
    width: 50%;
  }
}
