.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-content h3 {
  margin-top: 0;
}

.modal-content p {
  margin-bottom: 10px;
}

.modal-content button {
  margin-right: 10px;
}

.input-class {
  margin-left: 10%;
  margin-right: 10%;
}

.input-container {
  align-items: center;
  margin: 15px;
}

.custom-price-label {
  margin-right: 10px;
}

.custom-price-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  width: 150px;
  font-size: 16px;
}

.custom-price-input-small {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  width: 50px;
  font-size: 16px;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

.admin-table {
  width: max-content;
  border-collapse: collapse;
  margin: 0 auto;
}

.admin-table thead tr {
  background-color: #c7d4e5;
}

.admin-table th,
.admin-table td {
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border: 1px solid #d3d3d3;
}

.admin-table th {
  font-weight: bold;
  padding: 7px;
}

.admin-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.admin-table tbody tr:hover {
  background-color: #e1e1e1;
}

.admin-table input[type="checkbox"] {
  margin-right: 5px;
}

.checkAll-label {
  font-weight: bold;
  cursor: pointer;
}

.checkAll-label:hover {
  text-decoration: underline;
}

.review-table {
  width: 100%;
  border-collapse: collapse;
}

.review-table thead tr {
  background-color: #c7d4e5;
}

.review-table th,
.review-table td {
  max-width: 100px;
  overflow: auto;
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border: 1px solid #d3d3d3;
}

.review-table th {
  font-weight: bold;
}

.review-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.review-table tbody tr:hover {
  background-color: #e1e1e1;
}

.header-large {
  font-family: "Outfit", sans-serif;
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 1%;
}
