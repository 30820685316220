/* Default styles */
.title {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 25px;
  margin-bottom: 15px;
  font-family: "Outfit", sans-serif;
}

.footer {
  height: 25px;
}

.main-text {
  font-family: "Outfit", sans-serif;
  font-size: 0.9em;
}

.main-text-bold {
  font-family: "Outfit", sans-serif;
  font-size: 0.9em;
  font-weight: bold;
}

.header-text-bold {
  font-family: "Outfit", sans-serif;
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 1%;
}

.header-mid {
  font-family: "Outfit", sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-sub {
  font-family: "Outfit", sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-large {
  font-family: "Outfit", sans-serif;
  font-size: 2.1em;
  font-weight: bold;
  margin-top: 5%;
  margin-bottom: 5px;
  margin-left: 1%;
}

.booking-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #f1f1f1; /* Light gray background color */
  border: 2px solid #ccc; /* Add a border with a color */
  border-radius: 5px; /* Rounded corners */
  opacity: 0.9; /* Reduced opacity for a subtle effect */
  padding-top: 5%;
  padding-bottom: 5%;
}

/* Slideshow styles */
.slideshow {
  position: relative;
  height: auto;
}

.slideshow img {
  width: 100%;
  height: 500px;
  object-fit: scale-down;
  border-radius: 3px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 1%;
  height: auto;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8); /* Background color */
  font-size: 25px;
  user-select: none;
}

.arrow.left {
  left: 0;
}

.arrow.right {
  right: 0;
}

.left,
.right {
  display: inline-block;
}

/* About The Owner */
.container-owner {
  display: flex;
}

.image-owner {
  flex: 0 0 auto; /* prevent the image from stretching */
  margin-right: 3%;
  margin-left: 3%;
  height: 140px;
  object-fit: cover;
  border-radius: 5px;
}

.content-owner {
  flex: 1; /* make the content div take up all the remaining space */
}

.write-review-button {
  background-color: #f0f0f0; /* Light grey background color */
  color: #333; /* Dark grey text color */
  padding: 8px 12px; /* Adjust padding as needed */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 14px; /* Adjust font size as needed */
  margin-top: 5%;
  margin-bottom: 5px;
  margin-left: 3%;
}

.header-button-container {
  display: flex;
  align-items: center; /* Align items vertically at the center */
}

.reply-container {
  margin-top: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  border-left: 4px solid #ccc;
}

.reply-text {
  color: #555;
}

/* Styles for screens smaller than 768px */
@media (max-width: 850px) {
  .title {
    font-size: 1.5em;
    margin-left: 20%;
    margin-right: 20%;
  }

  .booking-form-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: white; /* Light gray background color */
    border: none; /* Add a border with a color */
    border-radius: none; /* Rounded corners */
    opacity: none; /* Reduced opacity for a subtle effect */
    padding-top: 2%;
    padding-bottom: 0;
  }

  .date-selectors-container {
    display: block;
    width: 100%;
    justify-content: space-between;
    margin: 5px 0;
    align-items: center;
  }

  .dateSelect {
    width: 100%;
  }

  .date-selectors-container > div {
    margin: 5px;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
  }

  .booking-form-content > div:not(.date-selectors-container) {
    width: 100%;
    margin: 5px;
  }
}

/* Styles for screens smaller than 550 */
@media (max-width: 550px) {
  .title {
    font-size: 1.1em;
    margin-left: 20%;
    margin-right: 20%;
  }

  .main-text {
    font-size: 0.9em;
  }

  .header-mid {
    font-size: 1.2em;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  .header-mid {
    font-size: 1em;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .booking-form-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: white; /* Light gray background color */
    border: none; /* Add a border with a color */
    border-radius: none; /* Rounded corners */
    opacity: none; /* Reduced opacity for a subtle effect */
    padding-top: 7%;
    padding-bottom: 0;
  }

  .date-selectors-container {
    display: block;
    width: 100%;
    justify-content: space-between;
    margin: 5px 0;
    align-items: center;
  }

  .dateSelect {
    width: 100%;
  }

  .date-selectors-container > div {
    margin: 5px;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
  }

  .booking-form-content > div:not(.date-selectors-container) {
    width: 100%;
    margin: 5px;
  }

  .header-mid {
    font-family: "Outfit", sans-serif;
    font-size: 0.9em;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .header-sub {
    font-family: "Outfit", sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .header-large {
    font-family: "Outfit", sans-serif;
    font-size: 2.1em;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 5px;
    margin-left: 1%;
  }

  .slideshow img {
    width: 100%;
    height: 300px;
    object-fit: scale-down;
    border-radius: 3px;
  }
}
