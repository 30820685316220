.header {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 1%;
  margin-bottom: 5px;
  justify-content: center;
}

.header-large-review {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 2%;
  margin-bottom: 2%;
  justify-content: center;
  text-align: center;
}

.centered-div {
  text-align: center;
}

.star {
  cursor: pointer;
  margin: 2px;
  width: 4%;
  filter: brightness(0%) sepia(100%) hue-rotate(200deg) saturate(1000%)
    contrast(100%) brightness(150%);
}

.errorDiv {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 2%;
  margin-bottom: 2%;
  justify-content: center;
  text-align: center;
  color: #c42525;
}

.errorDiv2 {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1em;
  margin-top: 2%;
  margin-bottom: 2%;
  justify-content: center;
  text-align: center;
  color: #c42525;
}

.errorDivSuccess {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 2%;
  margin-bottom: 2%;
  justify-content: center;
  text-align: center;
  color: #5cb85c;
}

.unconfirmed-status {
  display: inline-block;
  padding: 5px 10px;
  background-color: #2196f3; /* Blue color */
  color: white; /* Text color */
  border-radius: 5px; /* Rounded corners */
}

.tooltip-content {
  font-size: 20px; /* Adjust the font size as needed */
  max-width: 400px; /* Adjust the max width as needed */
  color: white; /* Set the text color */
}

.totalsDiv {
  width: 50%;
}

@media (max-width: 700px) {
  .totalsDiv {
    width: 100%;
  }
}
