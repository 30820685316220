.login-button {
  background-color: #007bff; /* Blue color for the button */
  color: white; /* White text color */
  padding: 10px 15px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Make buttons fill the width */
  margin-top: 5px; /* Adjust margin as needed */
}

.login-button-fixed {
  background-color: #f0f0f0; /* Light grey background color */
  color: #333; /* Dark grey text color */
  padding: 8px 12px; /* Adjust padding as needed */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 14px; /* Adjust font size as needed */
  position: fixed;
  right: 40px;
  top: 30px;
}

.createAccountButton {
  background-color: #4caf50; /* Green color for buttons */
  color: white; /* White text color */
  padding: 10px 15px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Make buttons fill the width */
  margin-top: 5px; /* Adjust margin as needed */
}

.login-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.createAccountButton:hover {
  background-color: #45a049; /* Darker green on hover */
}

.loginContainer {
  background-color: #fff; /* White background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow outline */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding around the content */
  width: 40%; /* Maximum width for responsiveness */
  margin: 0 auto; /* Center the container horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginTextBox {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%; /* Adjust the width to leave room for padding */
  padding: 10px; /* Increase padding for a bigger input field */
  font-size: 16px; /* Increase font size for better readability */
  border: 1px solid #ccc; /* Add a border for styling */
  border-radius: 5px; /* Rounded corners */
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

.orContainer {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.orHr {
  flex-grow: 1;
  height: 1px;
  margin: 0 10px;
  background-color: #ccc;
}

.orText {
  color: #666;
}

.createAccountButton {
  background-color: #4caf50; /* Green color for the "Create Account" button */
  color: white; /* White text color */
  padding: 10px 15px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Make the button fill the width */
  margin-top: 5px; /* Adjust margin as needed */
}

.createAccountButton:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Styles for screens smaller than 550 */
@media (max-width: 550px) {
  .login-button-fixed {
    padding: 6px 6px; /* Adjust padding as needed */
    right: 15px;
    top: 20px;
    z-index: 1;
  }

  .loginContainer {
    width: 80%; /* Maximum width for responsiveness */
  }
}
